<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- card title -->
          <h4 class="card-title">{{ $t('questionHistory') }}</h4>
          <hr />
          <!-- data rows  -->
          <Table
              :tableData="tableData"
              :fields="fields"
              :sortBy="sortBy"
              :filterOn="filterOn"
              :hide-show-more="true"
              :hideAddButton="true"
              id="historyQuestions"
          />
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
<script>
import Table from "@/components/elements/Tables.vue";
export default {
  components: {Table},
  props:{
    data: { type: Array, default: () => [] }, // data
  },
  computed:{
    tableData(){
      return this.data.map(el=>({
        ...el,
        send:3
      }))
    }
  },
  data(){
    return{
      fields: [
        { label: this.$t("date"), key: "questionDate", sortable: true },
        { label: this.$t("sent"), key: "send", sortable: false },
        { label: this.$t("unsent"), key: "failedQuestions", sortable: false },

      ],
      sortBy: "questionDate",
      filterOn: [],
    }
  }
}
</script>
<style >
#historyQuestions .card-body{
  padding: 0;
}
#historyQuestions .card{
  margin-bottom: 0;
  box-shadow: none;
}
</style>