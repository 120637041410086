<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "@/components/elements/DataViewer.vue";
import QuestionHistory from "@/components/QuestionHistory.vue";
import * as Clients from "@/services/clients.js";
import moment from "moment";
/**
 * Typography component
 */
export default {
  page: {
    title: "Client",
    meta: [{ name: "Client", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader,QuestionHistory },
  data() {
    return {
      title: this.$t("client"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.clients.text"),
          href: "/client/all",
        },
        {
          text: this.$t("client_information"),

          active: true,
        },
      ],
      user:null,
    };
  },
  computed:{
    numberOfQuestions(){
      return this.user?.questionsSnapshot?.size || 0
    },
    numberOfConsultation(){
      return this.user?.consultationSnapshot?.size || 0
    },
    numberOfContracts(){
      return this.user?.contractSnapshot?.size || 0
    },
    userConsultation(){
      let consultation = [];
      this.user?.consultationSnapshot?.forEach(el=>{
        consultation.push(el.data())
      })
      return consultation
    },
    userContracts(){
      let contracts = [];
      this.user?.contractSnapshot?.forEach(el=>{
        contracts.push(el.data())
      })
      return contracts
    },
    totalPaid(){
      const  totalPaidConsultation = this.userConsultation.reduce(
          (acc,current)=>current.isPaid ? +current.totalAmount + acc : acc
          ,0);

      const totalPaidContracts = this.userContracts.reduce(
          (acc,current)=>current.ContractStatus === 2 ? +current.TotalPayment + acc : acc
          ,0);
      return totalPaidConsultation + totalPaidContracts
    },
    userQuetionHistory(){
      return  this.user?.userData?.questionHistory || [];
    },
    data(){
      return [
        { key: this.$t("name"), value: this.user?.userData?.display_name || '' },
        { key: this.$t("phone"), value: this.user?.userData?.phone_number || '' },
        { key: this.$t("dashboard.number_of_contracts.text"), value: this.numberOfContracts },
        { key: this.$t("dashboard.number_of_consultation.text"), value: this.numberOfConsultation },
        { key: this.$t("dashboard.number_of_questions.text"), value: this.numberOfQuestions },
        { key: this.$t("total_paid"), value: `${this.totalPaid}  kwd` },
        { key: this.$t("lastQuestionDate"), value: this.user?.userData?.questionDate || this.$t('neverAskedBefore') },
        { key: this.$t("lastQuestionsCount"), value: this.user?.userData?.noQuestionsDaily || '0' },
        {
          key: this.$t("createdAt"),
          value:  moment(this.user?.createdAt).format("YYYY-MM-DD")
        },
      ]
    }
  },
  methods:{
   async loadingClient(){
     try {
      const res = await Clients.GetClientById(this.$route.params.id)
       this.user = {...res};
     }catch (err){
       console.log(err)
     }

    }
  },
  created() {
    this.loadingClient()
  }
};
</script>

<template>
  <Layout
    >
    <PageHeader :title="title" :items="items" />
    <DataViewer :title="this.$t('client_information')" :data="data" />
    <QuestionHistory  :data="userQuetionHistory" v-if="userQuetionHistory.length"/>
  </Layout>
</template>
