import "firebase/firestore";
import firebase from "firebase/app";

const db = firebase.firestore();
const collectionRef = db.collection("users");
const contracts = db.collection("Contract"),
    consultation = db.collection("CosultantRequest"),
    questions = db.collection("Questions");

export function GetAllUsers() {
    return new Promise((resolve, reject) => {
        const data = [];

        collectionRef
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        createdAt: nanosecondsToISO8601(doc.data().created_time.seconds),
                        ...doc.data(),
                    }); //contains the document data
                    // console.log(data);
                });
                resolve(data);
            })
            .catch((error) => {
                console.error("Error getting documents:", error);
                reject(error);
            });
    });
}

export async function GetClientById(id) {
    try {
        const doc = await collectionRef.doc(id).get();
        if (doc.exists) {
            const userData = doc.data(),
                createdAt = nanosecondsToISO8601(doc.data().created_time.seconds)
            const userRef = doc.ref;
            const userContractsRef = contracts.where('uid', '==', userRef),
                contractSnapshot = await userContractsRef.get();
            const userConsultationRef = consultation.where('clientID', '==', userRef),
                consultationSnapshot = await userConsultationRef.get();
            const userQuestionsRef = questions.where('uid', '==', userRef),
                questionsSnapshot = await userQuestionsRef.get();
            return {
                contractSnapshot,
                consultationSnapshot,
                questionsSnapshot,
                userData,
                createdAt
            };
        } else {
            throw new Error("client not found");
        }
    } catch (error) {
        console.error("Error getting content document:", error);
        throw error;
    }
}

export function GetClientsCount() {
    return new Promise((resolve, reject) => {
        collectionRef
            .get()
            .then((querySnapshot) => {
                resolve(querySnapshot.size);
            })
            .catch((error) => {
                console.error("Error getting documents:", error);
                reject(error);
            });
    });
}

function nanosecondsToISO8601(seconds) {
    const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
    const date = new Date(milliseconds);
    return date;
}
